<template>
  <div class="new_list" v-loading="loading" element-loading-text="拼命加载中">
    <div class="container">
      <div class="breadcrumb">
        <span class="name">当前位置：</span>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/news/' }">政策要闻</el-breadcrumb-item>
          <el-breadcrumb-item>{{$route.query.title || '新闻动态'}}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <h1><img src="@/static/news/news.png" alt="新闻动态图标" class="icon img">{{$route.query.title || '新闻动态'}}</h1>
      <div class="content flex">
        <div class="left">
          <ul class="tab" v-if="locationSite == ''">
            <li v-for="(n,i) in tabList" :key="i" @click="tabs(i,n)"><span
                :class="{'active':active == i}">{{ n }}</span>
            </li>
          </ul>
          <ul class="list">
            <li class="box" v-for="(n,i) in list" :key="i" @click="goRouter('/news/newDetail',n.id)">
              <h2 class="line_clamp2"><a href="javascript:void(0);"><span v-if="n.hasTop == 1" class="hot">置顶</span>{{ n.title }}</a></h2>
              <div class="bottom flex">
                <el-image
                    :src="n.thumbnail"
                    :alt="n.title"
                    :title="n.title"
                    class="banner"
                    v-if="n.thumbnail"
                >
                  <div slot="placeholder" class="image-slot">
                    加载中<span class="dot">...</span>
                  </div>
                  <div slot="error" class="image-slot">
                    <img src="~/static/public/defaults.png" class="banner_err" alt="">
                  </div>
                </el-image>
                <div class="info relative">
                  <div class="text line_clamp2">
                    {{ n.conAbstract }}
                  </div>
                  <div class="time">
                    {{ n.createTime }}
                    <span>{{ n.titleAbbr }}</span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <status :type="status.type" v-if="status.isShow"></status>
          <div class="pages">
            <el-pagination
                background
                class="work_pagination"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="page.pageNum"
                :page-size="page.pageSize"
                layout="prev, pager, next, total, jumper"
                v-show="page.total > 10"
                prev-text="上一页"
                next-text="下一页"
                :total="page.total">
            </el-pagination>
          </div>
        </div>
        <div class="right">
          <div class="box">
            <div class="title">
              <h2>政策法规</h2>
              <i class="icon icon el-icon-d-arrow-right" title="更多" @click="goRouter('/news/policy_list')"></i>
            </div>
            <ul>
              <li v-for="(n,i) in list1" :key="i" class="li line_clamp1">
                <a href="javascript:void(0);" :title="n.title"
                   @click="goRouter('/news/newDetail',n.id)">
                  <span v-show="n.author">「{{ n.author }}」</span>{{ n.title }}
                </a>
              </li>
            </ul>
          </div>
          <div v-for="(n,i) in adList" :key="i" class="relative">
            <router-link :to="n.picUrl" target="_blank" :title="n.adName">
              <img :src="n.picSrc" alt="广告" :title="n.adName" class="ad_img">
            </router-link>
            <span>广告</span>
          </div>
          <div class="box">
            <div class="title">
              <h2>通知公告</h2>
              <i class="icon icon el-icon-d-arrow-right" title="更多" @click="goRouter('/news/msg_list')"></i>
            </div>
            <ul>
              <li v-for="(n,i) in list2" :key="i" class="li line_clamp1">
                <a href="javascript:void(0);" :title="n.title"
                   @click="goRouter('/news/newDetail',n.id)">
                  <span v-show="n.author">「{{ n.author }}」</span>{{ n.title }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "new_list",
  metaInfo: {
    title: "兰州市新闻资讯-兰州市残疾人就业创业网络服务平台",
    meta: [
      {
        name: "keywords",
        content:
            "残疾人就业,残疾人证,兰州市残疾人联合会,中国残联,",
      },
      {
        name: "description",
        content:
            "残疾人资讯平台为广大残疾人、残疾人工作者和更为广泛的关心、关注中国残疾人事业的群体提供全面、及时、准确的服务。",
      },
    ],
  },
  data() {
    return {
      active: 0,
      tabList: [ "本省", "本市"],
      list: [],
      list1: [],
      list2: [],
      adList: [],
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        leve: "本省",
        city: "兰州",
        consultationClassName: '兰州新闻动态',
      },
      loading: false,
      locationSite: '',
      status:{
        type:'record',
        isShow:'',
      },
      path:'/news',
      cityType:'',
    }
  },
  methods: {
    tabs(i, val) {
      this.active = i;
      this.page.leve = val;
      this.news();
    },
    news() {
      this.page.consultationClassName = this.cityType+this.page.consultationClassName;
      if (this.$route.query.title){
        this.page.consultationClassName = this.$route.query.title;
      }
      this.$api.newsListApi(this.page).then(res => {
        this.loading = false;
        if (res.data.success) {
          this.list = res.data.data.records;
          this.page.total = res.data.data.total;
        }
        if (this.list <= 0){
          this.status.isShow = true;
        }
      }).catch(err => {
        this.loading = false;
        this.status.type = 'wifi';
        this.status.isShow = true;
      })
    },
    //政策
    policy() {
      console.log(this.page.leve);
      this.$api.newsListApi({
        pageNum: 1,
        pageSize: 10,
        city: this.page.city,
        leve: this.page.leve,
        consultationClassName: this.cityType+'兰州政策法规',
      }).then(res => {
        this.loading = false;
        if (res.data.success) {
          this.list1 = res.data.data.records;
        }
      }).catch(err => {
        this.loading = false;
      })
    },
    //通知
    message() {
      this.$api.newsListApi({
        pageNum: 1,
        pageSize: 10,
        city: this.page.city,
        leve: this.page.leve,
        consultationClassName: this.cityType+'兰州通知公告',
      }).then(res => {
        this.loading = false;
        if (res.data.success) {
          this.list2 = res.data.data.records;
        }
      }).catch(err => {
        this.loading = false;
      })
    },
    //广告
    getAdApi() {
      this.$api.adApi({classId: '40288113735a75d901735a75d9a40000'}).then(res => {
        this.adList = res.data.data;
      })
    },
    goRouter(url, id) {
      const {href} = this.$router.resolve({
        path: url,
        query: {
          id: id
        }
      })
      window.open(href, '_blank')
    },
    //分页
    handleCurrentChange: function (val) {
      this.page.pageNum = val;
      this.news();
      window.scrollTo(0, 0);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
  },
  created() {
    let locationText = localStorage.getItem('locationSelect');
    this.locationSite = localStorage.getItem('locationSite');
    this.news();
    this.policy();
    this.message();

  },
  mounted() {
    this.$emit('goRouter', '/news/')
  }
}
</script>

<style scoped lang="less">
@import "../../assets/css/news.css";
.hot{
  color: white;
  font-size: 14px;
  padding: 1px 5px;
  border-radius: 4px;
  background: #FC6C38;
  margin-right: 6px;
  vertical-align: middle;
}
</style>
